.fill {
  display: flex;
  flex-direction: column;
}
.custom-srcoll {
  overflow-x: hidden;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  width: 0;
  display: none;
  border: none;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 0;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.gradient-text {
  background-color: #f3ec78;
  background-image: linear-gradient(90deg,
  #FFF21F, 
  #FFE400,
  #FED700,
  #FDCF00,
  #FCCD00,
  #E5352B );
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}

.voice-bottom {
  background-color: aqua !important;
  display: flex;
}

/*Flex 容器*/
.flex__container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: rgb(96, 102, 102);
}

.flex_tag_container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-start;
  background-color: rgb(0, 0, 0);
}

.flex__item {
  background-color: aqua;
  padding-left: 1%;
  padding-right: 1%;
  display: inline-block;
  height: 40px;
  margin: 0;
}

.flex__item_2 {
  flex-grow: 2;
  text-align: right;
  background-color: aqua;
  display: inline-block;
  height: 40px;
  margin: 0;
}

.flex_video_list_item {
  padding-left: 1%;
  padding-right: 1%;
  display: block;
  height: 60px;
  margin: 0;
}

.video-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.video-desc {
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
/* 文字垂直置中 */
.text-v-center {
  background-color: rgba(134, 134, 134, 0.1);
  vertical-align: top;
}
.text-v-center:before {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
}
.text-v-center p {
  display: inline-block;
}

.user-pic-2 {
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
}

/* 文字垂直置中 */
.text-v-center-tag {
  vertical-align: top;
}
.text-v-center-tag:before {
  content: '';
  display: inline-block;
  width: 0;
  height: 5vh;
  vertical-align: middle;
}
.text-v-center-tag h5 {
  display: inline-block;
}

/* 文字垂直置中 */
.text-v-center-tags {
  background-color: rgba(145, 145, 145, 0.897);
  border-radius: 25px;
  vertical-align: top;
}
.text-v-center-tags:before {
  content: '';
  display: inline-block;
  width: 0;
  height: 5vh;

  vertical-align: middle;
}
.text-v-center-tags a {
  display: inline-block;
}

.user-pic-2 {
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.flex__item_3 {
  height: 60%;
  padding-left: 1%;
  padding-right: 1%;

  display: flex;

  align-items: center;
  margin: 10px;
}

.navigation {
  top: 0;
  left: 0;
  z-index: 65535;
}
.navigation:focus-within {
  opacity: 1;
}
