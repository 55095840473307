.hero {
  background-color: #1b1b1b;
  border-bottom: solid #898989 1px;
  display: flex;

  flex-direction: row-reverse;
  width: 100%;
  height: 70px;
  /* margin: 0 !important; */
  /* padding: 0 !important; */
}

nav {
  background: #1a1a1a;
  width: 100%;
  padding: 2%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  position: relative;
}
.logo {
  width: 40px;
}
.user-pic {
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 20px;
}
.user-pic-2 {
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
}
nav ul {
  width: 100%;
  text-align: right;
}
nav ul li {
  display: inline-block;
  list-style: none;
  margin: 10px 20px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

.sub-menu-wrap {
  position: absolute;
  top: 100%;
  right: 2%;
  width: 320px;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s;
}

.sub-menu-wrap.open-menu {
  max-height: 400px;
}

.sub-menu {
  background: #1a1a1a;
  padding: 20px;
  margin: 10px;
}
.user-info {
  display: flex;
  align-items: center;
}
.user-info h5 {
  font-weight: 500;
  color: #fbda2d;
  margin: 0 auto;
}
.user-info p {
  font-weight: 500;
  color: #fbda2d;
  margin: 0 auto;
}
.user-info img {
  width: 60px;
  border-radius: 50%;
}
.sub-menu hr {
  border: 0;
  height: 1px;
  width: 100%;
  background: #ccc;
  margin: 15px 0 10px;
}

.sub-menu-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fbda2d;
  margin: 12px 0;
}

.sub-menu-link p {
  width: 100%;
  margin: 0 auto;
}

.sub-menu-link img {
  width: 40px;

  /* border-radius: 50%; */
  padding: 4px;
  margin-right: 15px;
}

.sub-menu-link span {
  font-size: 20px;

  transition: transform 0.5s;
}

.sub-menu-link:hover span {
  transform: translateX(5px);
  color: #fff;
}

.sub-menu-link:hover p {
  font-weight: 600;
  color: #fff;
}

.fit-image {
  width: 100%;
  object-fit: cover;
}
