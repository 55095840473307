:root {
  --red_600_01: #d9442a;
  --red_600: #d8432a;
  --gray_900: #1b1b1b;
  --red_400: #f55151;
  --black_900_3f: #0000003f;
  --yellow_400_01: #ffe665;
  --yellow_A700: #fddf12;
  --yellow_400: #ffe666;
  --yellow_600: #fad92c;
  --black_900_01: #000000;
  --yellow_A400: #ffea10;
  --black_900: #110e0c;
  --deep_orange_700: #dc4e29;
  --yellow_900: #ea901d;
  --white_A700: #ffffff;
}

.font-zenDots {
  font-family: 'Zen Dots', sans-serif;
}

.content-box-payment {
  background-color: #000000 !important;
}

.container-payment {
  display: flex;
  flex-direction: column;
  gap: 34px;
  justify-content: flex-start;
  margin-top: 0.5rem;
  width: 100%;
}

.box-plan {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
}

.gradient-box-one {
  width: 100%;
  border-radius: 1rem;
  padding: 0.25rem;
  background: linear-gradient(to right, #ffea10, #ea901d, #d8432a);
}

.gradient-box-two {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  height: 300px;
}

.text-box-plan {
  color: white;
  margin-top: -20px;
  font-size: 26px;
}

.package-selection-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: #ffffff;
}

/* Inner container styling */
.inner-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px; /* Spasi antara item */
  width: 100%;
  height: 434px; /* Tinggi default */
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Efek bayangan */
}

.inner-content {
  width: 100%;
  height: 100%;
  padding: 0.25rem; /* Spasi dalam */
  border-radius: 1rem; /* Sudut bulat */
}

.items-package {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  width: 100%;
  height: 434px;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.items-gradient-package {
  width: 50%;
  height: 100%;
  border-radius: 1rem;
  padding: 0.25rem;
  background: linear-gradient(to bottom, #ffe666, #110e0c);
}

.items-gradient-lines {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: -2px;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0.75rem;
  border-radius: 1rem;
  justify-content: flex-start;
  background-color: #000000;
}

.name-package {
  cursor: pointer;
  line-height: normal;
  min-width: 10px;
  font-size: 10px;
  text-align: center;
}

.flex-container {
  display: flex;
}

.flex-item {
  flex: 1;
  background-color: #f0f0f0;
  padding: 20px;
  margin-right: 20px;
}

.flex-item:last-child {
  margin-right: 0;
}

.gradientPersonal {
  background: linear-gradient(158deg, #FFE666, #110E0C);
}

.gradientPersonalYear {
  background: linear-gradient(158deg, #F55151, #110E0C);
}

.grandientProfesional {
  background: linear-gradient(158deg, #FFEA10, #D8432A);
}

.text-plan {
  width: 230px;
  font-size: 14px;
  background-color: #ffe665;
  border-radius: 12px;
  display: inline-block;
  padding: 10px 20px;
  font: 12px;
  border: none;
  font-weight: bold;
}

.text-price-plan {
  height: 50px;
  margin-top: -55px;
  margin-left: 81%;
}

.btn-buy {
  border-radius: 30px;
  background-color: #ffe666;
  text-align: center;
  margin-top: 55%;
  border: none;
  text-decoration: none;
  color: inherit;
}

.text-buy {
  text-align: center;
  font-weight: bold;
}

.btn-buy:hover {
  color: white;
}

.content-plan {
  position: absolute;
  margin-top: 150px;
}

.content-box-plan {
  font-size: 14px;
}

@media (max-width: 768px) {
  .text-box-plan {
    position: absolute;
    color: white;
    margin-top: -100px;
    margin-bottom: -55px;
    font-size: 12px;
  }

  .content-box-plan {
    font-size: 10px;
  }

  .content-plan {
    position: absolute;
    margin-top: 100px;
    font-size: 10px;
  }
}

@media (max-width: 991px) {
  .text-box-plan {
    color: white;
    margin-top: -220px;
    font-size: 14px;
  }

  .text-plan {
    width: 150px;
    font-size: 9px;
    background-color: #ffe665;
    border-radius: 12px;
    display: inline-block;
    padding: 10px 20px;
    border: none;
  }

  .text-price-plan {
    height: 50px;
    margin-top: -48px;
    margin-left: 77%;
    font-size: 12px;
  }

  .btn-buy {
    border-radius: 30px;
    background-color: #ffe666;
    text-align: center;
    margin-top: 55%;
    border: none;
    text-decoration: none;
    color: inherit;
    font-size: 12px;
  }
}

@media (max-width: 1024px) {
  .text-box-plan {
    position: absolute;
    color: white;
    margin-top: -200px;
    font-size: 14px;
  }

  .content-box-plan {
    font-size: 10px;
  }

  .content-plan {
    position: absolute;
    margin-top: 100px;
    font-size: 10px;
  }

  .text-plan {
    width: 110px;
    font-size: 7px;
    background-color: #ffe665;
    border-radius: 12px;
    display: inline-block;
    padding: 5px 10px;
    border: none;
  }

  .text-price-plan {
    height: 50px;
    margin-top: -32px;
    margin-left: 77%;
    font-size: 6px;
  }

  .btn-buy {
    border-radius: 30px;
    background-color: #ffe666;
    text-align: center;
    margin-top: 55%;
    border: none;
    text-decoration: none;
    color: inherit;
    font-size: 9px;
  }

  .text-caption {
    font-size: 7px;
  }
}
