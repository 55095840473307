.video_upload_outter {
  background-color: #1b1b1b;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video_upload_panel {
  background-color: #d9d9d9;
  width: 1000px;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video_edit_panel {
  background-color: #d9d9d9;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.video_upload_block {
  font-size: large;
  margin: 0;
  padding: 0;
  align-content: center;
  text-align: center;
  width: 200px;
  height: 230px;
}

.video_upload_block > p {
  margin: 0;
}

.ant-steps {
  margin-top: 30px;
}

.video_edit_body_container {
  height: 100%;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
  font-size: 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  border-bottom: solid #acacac 1px;
}

.input_block {
  display: flex;
  flex-direction: column;
}

.input_block > label {
  margin-bottom: 10px;
}

.second_page_top_container {
  height: 200px;
  display: flex;
}

.second_page_top_block_left_panel {
  flex: 0.5;
  border: solid #000000 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.second_page_top_block_right_panel {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add_frame_btn {
  width: 50%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.second_page_top_block_right_panel > p {
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  font-size: 18px;
}

.second_page_slider {
  height: 20px;
  margin: 10px;
}

.second_page_bot_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.second_page_bot_block_records {
  overflow: auto;
  height: 200px;
  overflow-x: hidden;
  margin-bottom: 10px;
}

.ant-input {
  background-color: #d9d9d9;
  border-color: #000000;
  height: 40px;
}

.video_edit_third_page_block {
  height: 50px;
}

.video_edit_third_page_block > label {
  margin-right: 20px;
}

.ant-slider-track {
  opacity: 0;
}

.ant-upload-wrapper {
  width: 100%;
  height: 100%;
  background-color: #d9d9d9;
}

.ant-upload {
  border: 0 !important;
}

.list_title {
  margin-top: 10px;
  margin-bottom: 10px;
}

.list_title_row {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list_item_row {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-top: 5px;
}

.list_item_row > Button {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list_img {
  width: 60%;
  height: 100%;
  border: solid #000000 1px;
}

.list_img > img {
  width: 100%;
  height: 100%;
}

.ant-slider-rail {
  height: 20px !important;
}

.ant-slider-handle:after {
  height: 25px !important;
  width: 5px !important;
  border-radius: 0 !important;
}

.ant-select-selector {
  background-color: #d9d9d9 !important;
  border: solid #000000 1px !important;
}

.flex-container {
  display: flex;
}

.flex-container > div {
}
