.title {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
aside {
  background-color: #110E0C !important;
}
.title > img:last-of-type {
  height: 34px;
  margin-top: 5px;
}

.title > img:first-of-type {
  width: 43px;
  margin-right: 10px;
  margin-left: 24px;
  display: inline-block;
  vertical-align: middle;
}

.title > h1 {
  color: #fbda2d;
  display: inline-block;
  vertical-align: middle;
}

.ant-menu {
  display: flex;
  flex-direction: column;
  background-color: #000000;
  min-height: calc(100vh - 70px);
}

.ant-menu-item {
  color: #ffffff;
  height: 50px !important;
}

.ant-menu-item:hover {
  background-color: #3d3d3d !important;
}

.ant-menu-item-active {
  color: #ffffff !important;
}

.ant-menu-item-selected {
  color: #ffffff !important;
  background-color: #292929 !important;
}

.custom-link {
  text-decoration: none; /* 去掉文本的底线 */
  /* 可以添加其他自定义样式，如颜色、字体大小等 */
}

.custom-menu .ant-menu-item-selected:focus {
  background-color: transparent !important;
  /* 其他样式也添加 !important 标志以提高权重 */
}

