@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

::selection {
  color: #fff;
  background: #664aff;
}

.wrapper {
  max-width: 100%;
  margin: 0 auto;
}

.wrapper .search-input {
  background: #fff;
  width: 45vw;
  border-radius: 5px;
  position: relative;
  align-self: center;

  /* box-shadow: 0px 1px 5px 3px rgba(0,0,0,0.12); */
}

.search-input input {
  height: 55px;
  width: 100%;
  /* outline: none;
  border: none; */
  /* border: none;
  outline: none; */
  outline: none;
  border-color: #ffee17 !important;
  background: #000000 !important;
  border-radius: 5px;
  padding: 0 60px 0 20px;
  font-size: 18px;
  color: #efefef;
  /* box-shadow: 0px 1px 5px rgba(0,0,0,0.1); */
}

.search-input.active input {
  border-radius: 5px 5px 0 0;
}

.search-input .autocom-box {
  padding: 0;
  opacity: 0;
  pointer-events: none;
  max-height: 280px;
  overflow-y: auto;
}

.search-input.active .autocom-box {
  padding: 10px 8px;
  opacity: 1;
  pointer-events: auto;
}

.autocom-box li {
  list-style: none;
  padding: 8px 12px;
  display: none;
  width: 100%;
  cursor: default;
  border-radius: 3px;
}

.search-input.active .autocom-box li {
  display: block;
}
.autocom-box li:hover {
  background: #efefef;
}

.search-input .icon {
  position: absolute;
  top: 15%;
  right: 2%;
  width: 40px;
  text-align: center;

  font-size: 20px;
  color: #644bff;
  cursor: pointer;
}
