.home_container_inner {
  margin: auto;
  background-color: aqua;
}

.title_video_block_bottom {
  opacity: 0.25;
  height: 37px;
  background-color: #9a9a9a;
  display: flex;
  flex-direction: row;
  padding: 0px 40px 0px 40px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.title_video_block_bottom_top {

  display: flex;
  flex-direction: row;
  padding: 0px 40px 10px 40px;
  bottom: 0px;
  width: 100%;
  background-color: #000000;
}

.suggest_block {
  background-color: #110e0c;
  padding: 15px 10px;
  display: flex;
}

.suggest_block > div > p {
  width: 60px;
  padding: 0;
  margin: 0;
  margin-right: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.video_block {
  margin: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.video_block_top {
  position: relative;
  top: 0;
  left: 0;
}

.video_block_top_bot_left {
  position: absolute;
  bottom: 5px;
  left: 10px;
  display: flex;
}

.video_block_top_bot_right {
  position: absolute;
  bottom: 5px;
  right: 10px;
}

.video_block_desc {
  min-height: 50px;
  display: flex;
  margin: 0px 10px;
  margin-top: 5%;
}

.video_block_desc_left {
  display: flex;
  flex-direction: column;
}

.video_block_desc_left > p {
  padding: 0;
  margin: 0;
  display: flex;
  color: black;
  flex: 0.5;
  width: fit-content;
  justify-content: left;
  align-items: center;
}

.video_block_desc_right {
  display: flex;
  justify-content: center;
  align-items: center;
}
