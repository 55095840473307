.header {
  background-color: #1b1b1b;
  border-bottom: solid #898989 1px;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: 70px;
  margin: 0 !important;
  padding: 0 !important;
}

.header_right {
  display: flex;
  flex-direction: row-reverse;
}

.search_box {
  width: 90%;
  background: linear-gradient(#FFEE17, #E63C2B) padding-box, linear-gradient(to right, #FFEE17, #E63C2B) border-box;
  border-radius: 8px;
  border: 2px solid transparent;
}

.search_box > span > input {
  background-color: #1b1b1b !important;
  color: #ffffff !important;
}

.search_box > span > input:hover {
  border-right: 0 !important;
}

.search_box > span > input:focus {
  border-right: 0 !important;
}

.search_box > span > span {
  background-color: #1b1b1b !important;
}

.search_box > span > span > button {
  background-color: #1b1b1b !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  color: #ffd130 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
