.ant-table-tbody
  > tr.ant-table-row:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  td {
  background-color: #1b1b1b !important;
}

.ant-table {
  background-color: #1b1b1b !important;
  color: #ffffff !important;
  border-radius: 0 !important;
}

.ant-table-row > td {
  border-color: #9a9a9a !important;
}

.ant-table-cell {
  background-color: #1b1b1b !important;
  border-color: #9a9a9a !important;
}

.ant-table-cell-row-hover {
  background-color: #1b1b1b !important;
}

.ant-table-thead > tr > th {
  background-color: #1b1b1b !important;
  color: #ffffff !important;
  border-color: #9a9a9a !important;
}

.ant-table-thead > tr > th::before {
  background-color: #1b1b1b !important;
}

.ant-pagination-item-link {
  /* background-color: #FFFFFF; */
  color: #ffffff !important;
}

.ant-pagination-item {
  color: #ffffff;
}

.ant-pagination-item-active {
  background-color: #1b1b1b !important;
  border-color: #9a9a9a !important;
}

.ant-table-wrapper {
  background-color: #1b1b1b !important;
}
