.login-radius {
  border-radius: 10px;
}

.btn-primary {
  /* background-color: #FFEE17 !important; */
  background: linear-gradient(to right, #ffee17, #e63c2b) !important;
  border-style: none !important;
}

.left-div {
  display: flex;
  padding-left: 10px;
  height: 30px;
}
