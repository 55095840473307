.ant-upload-list-item-name{

    color: #ffffff !important;
}

.rcorners {
    border-radius: 25px;
    border: 2px dashed #ffffff;
    padding: 10px; 
    width: 100%;
    height:  100%;
    text-align: center;
}


.ant-input-affix-wrapper{
    color: #1f1f1f !important;
}


.search_ab_box {
    width: 80%;
  }
  
  
 .ant-input {
    &::placeholder {
      color: rgb(147, 147, 147) !important;
    }
  }

.ant-btn-primary {
    background-color:#b69802 !important;
}

.anticon-delete {
  color:#b69802 !important;
}

  /* .search_ab_box . */

  .scaled-video-player {
    
  }